import React, { Component } from 'react';

import classes from './ProgressBar.module.css';

class ProgressBar extends Component {

    state = {
        progress: this.props.points / this.props.whole * 100,
        style: {
            transform: 'translateX(-100%)'
        }
    }

    componentDidMount = () => {
        console.log(this.state.progress);
        this.setState({
            style: {transform: `translateX(-${100-this.state.progress}%)`}
        });
    }

    // componentDidUpdate = () => {

    // }

    // component
    
    render() {
        return (
            <div className={classes.ProgressBar}>

                <div className={classes.ProgressShadow}></div>

                <div className={classes.Points}>
                    {this.props.plus ? '+ ' : null}{this.props.points} Points
                </div>
                
                {/* <div className={classes.Progress} style={this.state.style}> */}
                <div className={classes.Progress} style={this.state.style}>
                    
                </div>

            </div>
        )
    }
}

export default ProgressBar;