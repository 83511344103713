import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import classes from './RoundResult.module.css';
import PlotMap from '../Plotter/PlotMap/PlotMap';
import ProgressBar from '../ProgressBar/ProgressBar';
import * as actions from '../../store/actions/index';
import Button from '../Button/Button';

const RoundResult = (props) => {

    const quitGame = () => {
        props.history.push('/');
        props.onQuitGame();
    }

    const restartGame = (gameType) => {
        console.log(gameType);
        props.onStartGame(gameType);
    }
    
    return (
        <div className={classes.RoundResult}>
            <div className={classes.MapContainer}>
                <PlotMap roundResult />
            </div>
            
            <div className={classes.Content}>

                {
                    props.gameEnded
                    ? <p>Congratulations! You scored <strong>{props.totalPoints}</strong> out of <strong>{props.maxPoints}</strong> Points.</p>
                    : (
                        <div>
                            <div className={classes.Data}>{props.distance.toFixed(0)}</div>
                            <div className={classes.Text}>Units Distance</div>
                        </div>
                    )
                }
                
                {
                    props.gameEnded 
                    ? <ProgressBar key={1} points={props.totalPoints} whole={props.maxPoints} />
                    : <ProgressBar key={2} points={props.roundPoints} whole={5000} plus />
                }
                
                {
                     props.gameEnded
                     ? (
                         <div className={classes.Buttons}>
                            <Button text={'Play Again'} onButtonClicked={() => restartGame(props.gameType)} />
                            <Button text={'Quit'} secondary={true} onButtonClicked={quitGame} />
                        </div>
                     )
                     : <Button text={'Next Round'} onButtonClicked={props.onNextRound} />
                }
                
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        totalPoints: state.game.points,
        roundPoints: state.game.locations[state.game.round].points,
        distance: state.game.locations[state.game.round].distance,
        roundEnded: state.game.roundEnded,
        gameEnded: state.game.gameEnded,
        maxPoints: state.game.maxPoints,
        gameType: state.game.mapType
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onNextRound: () => dispatch(actions.nextRound()),
        onQuitGame: () => dispatch(actions.quitGame()),
        onStartGame: (gameType) => dispatch(actions.startGame(gameType))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RoundResult));