import * as actions from './actions';
import axios from '../../axios';

export const setGuess = (latLng) => {
    return {
        type: actions.SET_GUESS,
        latLng: latLng
    }
}

export const makeGuess = () => {
    return {
        type: actions.MAKE_GUESS
    }
}

export const nextRound = () => {
    return {
        type: actions.NEXT_ROUND
    }
}

export const quitGame = () => {
    return {
        type: actions.QUIT_GAME
    }
}

export const setMapData = (mapData) => {
    return {
        type: actions.SET_MAP_DATA,
        mapData: mapData
    }
}

export const initMapData = () => {
    return dispatch => {
        axios.get('/maps.json')
            .then(response => {
                dispatch(setMapData(response.data));
            }) 
            .catch(error => {
                console.log(error);
            });
    }
}

export const startGame = (gameType) => {
    return {
        type: actions.START_GAME,
        gameType: gameType
    }
}