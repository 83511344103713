import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import classes from './PointDisplay.module.css';
import * as actions from '../../store/actions/index';


const PointDisplay = (props) => {

    const quitGame = () => {
        props.history.push('/');
        props.onQuitGame();
    }

    return (
        <div className={classes.PointDisplay}>
            <div>
                <div className={classes.Data}>{props.points}</div>
                <div className={classes.Text}>Points</div>
            </div>
            <div className={classes.Divider}></div>
            <div>
                <div className={classes.Data}>{props.round + '/' + props.finalRound}</div>
                <div className={classes.Text}>Round</div>
            </div>
            <div className={classes.Divider}></div>
            <div className={classes.Quit} onClick={() => quitGame()}>
                Quit
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        round: state.game.round + 1,
        finalRound: Object.keys(state.game.locations).length,
        points: state.game.points
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onQuitGame: () => dispatch(actions.quitGame())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PointDisplay));