import React, { Component } from 'react';

import MapItem from '../../components/MapItem/MapItem';
import classes from './MapList.module.css';
import PanoramaViewer from '../../components/PanoramaViewer/PanoramaViewer';

class MapList extends Component {

	state = {
		mapItems: [
			{
				id: 0,
				gameType: 'guildwars2',
				title: 'Guild Wars 2',
				description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Repellat doloribus nihil reiciendis omnis reprehenderit placeat quibusdam? Inventore similique, alias quo facere, voluptates illo assumenda tenetur, dolorem aperiam reiciendis eos dolore.',
				headerImage: 'https://roy-van-dijk.github.io/mmo-guesser-assets/banners/guildwars2.jpg',
				logo: 'https://roy-van-dijk.github.io/mmo-guesser-assets/logos/guildwars2.png',
				requiresBackground: true,
				disabled: false,
				locations: 85,
				plays: 233,
				likes: 52
			},
			{
				id: 1,
				gameType: 'runescape',
				title: 'RuneScape',
				description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Repellat doloribus nihil reiciendis omnis reprehenderit placeat quibusdam? Inventore similique, alias quo facere, voluptates illo assumenda tenetur, dolorem aperiam reiciendis eos dolore.',
				headerImage: 'https://roy-van-dijk.github.io/mmo-guesser-assets/banners/rs.jpg',
				logo: 'https://roy-van-dijk.github.io/mmo-guesser-assets/logos/runescape.png',
				requiresBackground: false,
				disabled: false,
				locations: 85,
				plays: 233,
				likes: 52
			},
			{
				id: 2,
				gameType: 'worldofwarcraft',
				title: 'World of Warcraft',
				description: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Repellat doloribus nihil reiciendis omnis reprehenderit placeat quibusdam? Inventore similique, alias quo facere, voluptates illo assumenda tenetur, dolorem aperiam reiciendis eos dolore.',
				headerImage: 'https://roy-van-dijk.github.io/mmo-guesser-assets/banners/worldofwarcraft.jpg',
				logo: 'https://roy-van-dijk.github.io/mmo-guesser-assets/logos/worldofwarcraft.svg',
				requiresBackground: false,
				disabled: true,
				locations: 85,
				plays: 233,
				likes: 52
			},
		]
	}
	
	render() {
		return (
			<React.Fragment>
				<h1 className={classes.Logo}><span className={classes.Mmo}>MMO</span><span className={classes.Guesser}>Guesser</span></h1>
				<div className={classes.MapList}>
					{
						this.state.mapItems.map(mapItem => (
							<MapItem key={mapItem.id} mapItem={mapItem} />
						))
					}
				</div>
				<div className={classes.PanoramaWrapper}>
					<PanoramaViewer autoRotate randomize />
				</div>
			</React.Fragment>
			
		)
	}
}

export default MapList;