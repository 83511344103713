
import React, { Component } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import MapList from './containers/MapList/MapList';
import Game from './containers/Game/Game';
import * as actions from './store/actions/index';

class App extends Component {

	componentDidMount = () => {
		this.props.onInitMapData();
	}

	render() {
		return (
			<div>
				<BrowserRouter>
					<Switch>
						<Route path="/" exact component={MapList} />
						<Route path="/game" component={Game} />
					</Switch>
				</BrowserRouter>
			</div>
		)
	}
}

const mapDispatchToProps = dispatch => {
    return {
        onInitMapData: () => dispatch(actions.initMapData())
    }
}

export default connect(null, mapDispatchToProps)(App);
