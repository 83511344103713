import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Pannellum } from 'pannellum-react';

import classes from './PanoramaViewer.module.css';
import image from '../../assets/7.jpg';

export class PanoramaViewer extends Component {
	state = {
		config: {
			autoRotate: this.props.autoRotate ? 3 : 0,
			autoLoad: true,
			width: '100%',
			height: '100%',
			sceneFadeDuration: 3000,
			showControls: false,
			hfov: 125,
			maxHfov: 125,
			maxPitch: 125,
			minPitch: -125
		}
	}

	render() {
		return (
			<React.Fragment>
				<div className={classes.PanoramaViewer}>
					<Pannellum
						image={this.props.image}
						{...this.state.config}>
					</Pannellum>
				</div>
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => {
    return {
        image: state.game.locations[state.game.round] ? state.game.locations[state.game.round].image : image
    }
}

export default connect(mapStateToProps)(PanoramaViewer);