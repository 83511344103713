import React, { Fragment, useState } from 'react';
import { MapContainer, ImageOverlay, useMapEvent, Marker, useMap, Polyline } from 'react-leaflet';
import Leaflet, { CRS } from 'leaflet';
import { connect } from 'react-redux';

import classes from './PlotMap.module.css';
import star from '../../../assets/star.svg';
import pointMarker from '../../../assets/marker.svg';
import * as actions from '../../../store/actions/index';

const PlotMap = (props) => {

	const [plotPoint, setPlotPoint] = useState([-10000,-10000]);
	const bounds = Leaflet.latLngBounds(Leaflet.latLng(0, 0), Leaflet.latLng(props.mapSize.lat, props.mapSize.lng));
	const guessMarkerIcon = Leaflet.icon({
		iconUrl: pointMarker,
		iconSize: new Leaflet.point(50, 50),
		iconAnchor: [25, 50]
	});
	const answerMarkerIcon = Leaflet.icon({
		iconUrl: star,
		iconSize: new Leaflet.point(40, 40)
	});

	const PlotMarker = () => {
		useMapEvent('click', e => {
			props.onSetGuess({ lat: e.latlng.lat, lng: e.latlng.lng });
			console.log(e.latlng.lat, e.latlng.lng);

			setPlotPoint([e.latlng.lat, e.latlng.lng]);
		});
		
		return <Marker icon={guessMarkerIcon} position={plotPoint}></Marker>;
	}

	const RoundMarker = (props) => {
		const map = useMap();

		if (props.end) {
			map.setZoom(-2);
		} else {
			map.fitBounds(props.bounds);
		}
		
		return (
			<Fragment>
				<Marker icon={guessMarkerIcon} position={props.bounds[0]}></Marker>
				<Marker icon={answerMarkerIcon} position={props.bounds[1]}></Marker>
				<Polyline positions={props.bounds} pathOptions={{color: '#fc4a1a'}} />
			</Fragment>
		)
	}

	return (
		<div className={classes.PlotMap}>
			<MapContainer 
				crs={CRS.Simple}
				className={classes.LeafletContainer} 
				center={[props.mapSize.lat/2, props.mapSize.lng/2]} 
				minZoom={-4}
				maxZoom={5}
				zoom={0}
				attributionControl={''}
				maxBoundsViscosity={0.1}
				maxBounds={bounds}
				// dragging={props.roundResult ? false : true}
				// scrollWheelZoom={props.roundResult ? false : true}
				zoomControl={props.roundResult ? false : true}>

				{
					props.roundResult && !props.gameEnded
						? <RoundMarker end={false} bounds={[[props.guess.lat, props.guess.lng], [props.answer.lat, props.answer.lng]]} /> 
						: !props.gameEnded ? <PlotMarker /> : null
				}

				{
					props.gameEnded 
					? (
						<Fragment>
							{
								Object.keys(props.locations).map(location => {
									return (
										<RoundMarker 
											key={location} 
											end={true}
											bounds={[
												[props.locations[location].guess.lat, props.locations[location].guess.lng], 
												[props.locations[location].answer.lat, props.locations[location].answer.lng]
											]} 
										/> 
									)
								})
							}
						</Fragment>
					)
					: null
				}

				<ImageOverlay
					url={props.map}
					bounds={[
						[0, 0],
						[props.mapSize.lat, props.mapSize.lng],
					]}
					opacity={1}
					zIndex={10} />

			</MapContainer>	
		</div>
	)
}

const mapStateToProps = state => {
    return {
		map: state.game.map,
		mapSize: state.game.mapSize,
		guess: state.game.locations[state.game.round] ? state.game.locations[state.game.round].guess : {lat: 0, lng: 0},
		answer: state.game.locations[state.game.round] ? state.game.locations[state.game.round].answer : {lat: 0, lng: 0},
		locations: state.game.locations,
		gameEnded: state.game.gameEnded
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSetGuess: (latLng) => dispatch(actions.setGuess(latLng)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlotMap);