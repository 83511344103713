import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import classes from './MapItem.module.css';
import * as actions from './../../store/actions/index';
import Button from './../Button/Button';

const MapItem = (props) => {
    return (
        <div className={classes.MapItem} style={{backgroundImage: 'url(' + props.mapItem.headerImage + ')'}}> 
            <h2>{props.mapItem.title}</h2>           
            <div className={classes.Inner}>
                <div className={`${classes.ImageContainer} ${props.mapItem.requiresBackground ? classes.Bg : ''}`}>
                    <img src={props.mapItem.logo} alt="" className={classes.Logo} />
                </div>
                
                <div className={classes.PlayButton}>
                    <NavLink to="/game" onClick={() => props.onStartGame(props.mapItem.gameType)}>
                        <Button disabled={props.mapItem.disabled} className={classes.PlayButton} text={'Play'}></Button>
                    </NavLink>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        onStartGame: (gameType) => dispatch(actions.startGame(gameType))
    }
}

export default connect(null, mapDispatchToProps)(MapItem);