import React, { Component } from 'react';
import { connect } from 'react-redux';

import classes from './Game.module.css';
import PanoramaViewer from '../../components/PanoramaViewer/PanoramaViewer';
import Plotter from '../../components/Plotter/Plotter';
import PointDisplay from '../../components/PointDisplay/PointDisplay';
import * as actions from '../../store/actions/index';
import RoundResult from '../../components/RoundResult/RoundResult';

class Game extends Component {

    render() {
        return (
            <div className={classes.Game}>
				
				{
					this.props.roundEnded ? <RoundResult /> : (
						<React.Fragment>
							<PanoramaViewer />
							<Plotter />
							<PointDisplay />
						</React.Fragment>
					)
				}
				
			</div>
        )
    }
}

const mapStateToProps = state => {
    return {
        roundEnded: state.game.roundEnded
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onMakeGuess: (latLng) => dispatch(actions.makeGuess(latLng)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Game);
