import update from 'immutability-helper';

import * as actions from './../actions/actions';

let mapData = {};

const initialState = {
	map: '',
	mapType: null,
	mapSize: {
		lat: 0,
		lng: 0
	},
	points: 0,
	maxPoints: 25000,
	time: 0,
	round: 0,
	roundEnded: false,
	gameEnded: false,
	locations: {}
}

const getDistance = (pointsA, pointsB) => {
	let a = pointsA.lat - pointsB.lat;
	let b = pointsA.lng - pointsB.lng;
	return Math.sqrt( a*a + b*b );
}

const getPoints = (distance) => {
	if (distance > 500) {
		return 0;
	} else if (distance < 15) {
		return 5000;
	} else {
		return 5000 - Math.floor(distance * 10);
	}
}

const clearGame = (state) => {
	return update(state, {
		mapType: {$set: null},
		map: {$set: ''},
		points: {$set: 0},
		time: {$set: 0},
		round: {$set: 0},
		roundEnded: {$set: false},
		gameEnded: {$set: false},
		locations: {$set: {}}
	})
}

const getLocations = (gameType) => {
	let locations = getRandom(mapData[gameType].locations, 5);

	for (let location in locations) {
		locations[location].guess = {lat: 0, lng: 0};
		locations[location].points = 0;
		locations[location].distance = 0;
		locations[location].set = false;
	}

	return locations;
}

const getRandom = (obj, n) => {
    let result = {},
        len = Object.keys(obj).length,
        taken = {};
    if (n > len)
        throw new RangeError("getRandom: more elements taken than available");
    while (n--) {
        let x = Math.floor(Math.random() * len);
        result[n] = obj[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
}


const reducer = (state = initialState, action) => {
	switch(action.type) {
		case actions.SET_GUESS:
			return update(state, {
				locations: {
					[state.round]: {
						set: {$set: true},
						guess: {
							lat: {$set: action.latLng.lat},
							lng: {$set: action.latLng.lng}                    
						}
					}
				}
			});
		case actions.MAKE_GUESS:
			let loc = state.locations[state.round];
			let distance = getDistance(loc.answer, loc.guess);
			let points = getPoints(distance);

			return update(state, {
				roundEnded: {$set: true},
				points: {$set: state.points + points},
				locations: {
					[state.round]: {
						points: {$set: points},
						distance: {$set: distance}
					}
				}
			});
		case actions.NEXT_ROUND:
			if (state.round === Object.keys(state.locations).length - 1) {
				return update(state, {
					gameEnded: {$set: true}
				});
			}
			return update(state, {
				round: {$set: state.round + 1},
				roundEnded: {$set: false}
			});
		case actions.QUIT_GAME:
			return clearGame(state);
		case actions.SET_MAP_DATA:
			mapData = action.mapData;
			return state;
		case actions.START_GAME:
			let newState = clearGame(state);
			newState.mapType = action.gameType;
			newState.map = mapData[action.gameType].map;
			newState.mapSize = mapData[action.gameType].mapSize;
			newState.locations = getLocations(action.gameType);
			return newState;
		default:
			return state
	}
}

export default reducer;
