import React from 'react';

import classes from './Button.module.css';

const Button = (props) => {
    return (
        <button
            disabled={props.disabled}
            onClick={props.onButtonClicked}
            className={`${classes.Button} ${props.secondary ? classes.Secondary : ''}`}
            >
            {props.text}
        </button>
    )
}

export default Button;