import React from 'react';
import { connect } from 'react-redux';

import classes from './Plotter.module.css';
import PlotMap from './PlotMap/PlotMap';
import * as actions from '../../store/actions/index';

const Plotter = (props) => (
    <div className={classes.Plotter}>
        <div className={classes.PlotMap}>
            <PlotMap /> 
        </div>
        <button disabled={!props.guessMade} onClick={props.onMakeGuess}>Make Guess</button>
    </div>
)

const mapStateToProps = state => {
    return {
        guessMade: state.game.locations[state.game.round] ? state.game.locations[state.game.round].set : false
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onMakeGuess: () => dispatch(actions.makeGuess())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Plotter);